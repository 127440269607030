























import { defineComponent, reactive, ref } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import validator from "validator";

export default defineComponent({
  props: {
    messageId: {
      type: String,
      required: true,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      empty: false,
      loaded: false,
      error: false as boolean | number,
      invoice: "",
    });

    const model = reactive({
      email: "",
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.participantNotFound")}`;
        case 406:
          return `${root.$tc("layout.errors.alreadyPaid406")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        messageId: props.messageId,
        eventId: root.$route.params.id,
        email: model.email,
      };

      state.loading = true;

      axiosInstance
        .post("mail-task/test", data)
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.testEmail.success"),
          });
          model.email = "";

          setTimeout(() => {
            emit("close");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const close = () => {
      emit("close");
    };

    const rules = {
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };

    return { form, state, model, onSubmit, close, rules };
  },
});
